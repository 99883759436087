import {
  Button, Caption, Grid, GridColumn, useSnackbar
} from "@walmart-web/livingdesign-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postService } from "../../../../axios/axios";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import MuiTagItem from "../../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import { COMPOSITE_SEARCH_SERVICE } from "../../../../constants/baseURLs";
import { matterListStatusTagColors } from "../../../../constants/colors";
import {
  CS_DEFAULT_SORT_QUERY,
  CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  MATTER_DEFAULT_SORT_QUERY,
  SELECT_FROM_MATTERS, SELECT_TIMEKEEPERS
} from "../../../../constants/constants";
import { MATTER_MESSAGE } from "../../../../constants/messages";
import {
  ENABLE_SERVER_SIDE_FEATURES_MATTER,
  ROWS_PER_PAGE_MATTER,
  ROWS_PER_PAGE_OPTIONS_MATTER,
} from "../../../../constants/tableConfigs";
import { CANCEL } from "../../../../constants/actionButtons";
import { MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { getErrorMessage, getSensitiveMatterPayload, returnPracticeAreaName } from "../../../../helpers/utils";
import { isValueEmptyCheck } from "../../../../helpers/validationUtils";
import { setMatterListingData } from "../../../../store/actions/matterAction";
import { setRemoveSelectedMattersData } from "../../../../store/actions/organizationAction";
import RemoveFromMatterstyles from "./RemoveTimekeepersSelectMatters.styles";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../../helpers/csQueryUtils";

/**
 * Service of Process Select Matters data component
 */
const RemoveTimekeepersSelectMatters = () => {
  const classes = RemoveFromMatterstyles();
  const { identifier } = useSelector((state) => state?.organization?.organizationDetailValues);

  const displayValue = (value, tableMeta) => {
    return (
      <Caption as="p">{isValueEmptyCheck(value)}</Caption>
    )
  }
  const renderStatus=(value) => {
    return <MuiTagItem
      value={isValueEmptyCheck(value)}
      color={matterListStatusTagColors[value]?.color || "gray"}
      variant={matterListStatusTagColors[value]?.variant || "tertiary"} />;
  }
  const COLUMNS = [
    {
      name: "id",
      label: "id",
      colKey: "id",
      options: {
        display: false,
      },
    },
    {
      name: "matterNumber",
      label: "Matter No.",
      colKey: "matterNumber",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Matter Number: ${v}` },
      },
    },
    {
      name: "matterName",
      label: "Matter Name",
      colKey: "matterName",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Matter Name: ${v}` },
      },
    },
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        sort: true,
        customBodyRender: renderStatus,
        customFilterListOptions: { render: (v) => `Status: ${v}` },
      },
    },
    {
      name: "matterType",
      label: "Matter Type",
      colKey: "matterType",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Matter Type: ${v}` },
      },
    },
    {
      name: "creationDate",
      label: "Create Date",
      colKey: "creationDate",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return isValueEmptyCheck(value) !== "-" ? moment(value)?.format(MOMENT_DATE_FORMAT) : "-"
        }
      },
    },
    {
      name: "leadAttorney",
      label: "Lead Attorney",
      colKey: "leadAttorney",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => displayValue(value, tableMeta),
        customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
      },
    },
    {
      name: "practiceArea",
      label: "Practice Area",
      colKey: "practiceArea",
      options: {
        display: false,
        customBodyRender: (value) => returnPracticeAreaName(value, practiceAreas),
        customFilterListOptions: { render: (v) => `Practice Area: ${v}` },
      }
    }
  ];

  const [matterColumns, setmatterColumns] = useState(COLUMNS);
  const [rowsPerPageMatter, setRowsPerPageMatter] = useState(ROWS_PER_PAGE_MATTER);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch()
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const { matterListingData, practiceAreas } = useSelector((state) => state?.matter);
  const matterAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters);
  const userDetails = useSelector((state) => state?.user?.userDetail?.attributes);
  const { addSnack } = useSnackbar();
  const [practiceAreaDropDown, setPracticeAreaDropDown] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [sortQuery, setSortQuery] = useState(MATTER_DEFAULT_SORT_QUERY);

    useEffect(() => {
      if(practiceAreas?.length > 0) {
        const practiceAreasList = [...new Set(practiceAreas?.map(val => val?.name))];
        setPracticeAreaDropDown(practiceAreasList);
      }
    }, [practiceAreas]);


  // Conversion of pratice area code to corresponding names in hidden column
  useEffect(() => {
    if (practiceAreaDropDown?.length > 0) {
      setmatterColumns(COLUMNS);
    }
  }, [practiceAreaDropDown]);

  const FILTER_CONFIGS = [
    {
      colKey: "status",
      type: "checkbox",
      label: "Status",
      defaultOpen: true,
      options: ["Open", "Closed"],
    },
    {
      colKey: "matterNumber",
      type: "matterDropdownAutocomplete",
      label: "Matter Number",
      defaultOpen: true,
    },
    {
      colKey: "matterName",
      type: "matterDropdownAutocomplete",
      label: "Matter Name",
      defaultOpen: true,
    },
    {
      colKey: "matterType",
      type: "masterDataMultiAutocomplete",
      dataType: "matterType",
      label: "Select Matter Type",
      defaultOpen: true,
    },
    {
      colKey: "practiceArea",
      type: "autocompleteMultiSelect",
      label: "Practice Area",
      defaultOpen: true,
      options: practiceAreaDropDown,
    },
    {
      colKey: "leadAttorney",
      type: "peopleDropdownAutocomplete",
      label: "Lead Attorney",
      defaultOpen: true,
      options: [],
    },
  ];

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPageMatter(rowsPerPage);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getMatterData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };

  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {string} name
  * @param {string} direction
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name,direction);
    setSortQuery(sortQuery);
  };

  const matterTableCustomOptions = {
    filterConfigs: FILTER_CONFIGS,
    responsive: "standard",
    rowsPerPage: rowsPerPageMatter,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_MATTER,
    isMatter: true,
    totalCount: matterListingData?.count,
    serverSide: ENABLE_SERVER_SIDE_FEATURES_MATTER,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
  };

  /**
* Function to call Matter Search Service
* @param {string} query
*/
  const getMatterData = (paginationQuery) => {
    setLoading(true);
    const defaultQuery = {
      "filters": [
        formCsFilterObject("isActive", "match", true),
        formCsFilterObject("organizations.id", "match", identifier)
      ],
      "operation": "AND",
      "properties": null
    }

    const sortQueryMatter = {...sortQuery};
		if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryMatter.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }

    let sensitiveMatterQuery = getSensitiveMatterPayload(matterAccessibility?.viewAll, userDetails?.userUniqueId);
    const query = formCompositeSearchQuery([CS_SCOPES.MATTER], filterQuery, searchQuery, sortQueryMatter, paginationQuery, {}, defaultQuery,sensitiveMatterQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${identifier}&options=restrictMode`,
      query
    )
    .then((response) => {
      dispatch(setMatterListingData({
        matters: response?.data?.matter?.data,
        count: response?.data?.matter?.count
      }));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      addSnack({
        message: getErrorMessage(error)
      });
    });
  };

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPageMatter,
    }
    getMatterData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  /**
  * function will use when row is select/deselect
  */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const mattersData = matterListingData?.matters;
    const result = allSelected?.map(item => mattersData && mattersData[item?.dataIndex]);
    setSelectRowsData(selectRowsData);
    setSelectedData(result)
  }

  /**
* function will use when we click on select matters button to navigate to matters screen
*/
  const selectTimekeepers = () => {
    dispatch(setRemoveSelectedMattersData(selectedData))
    history.push("/manageTimekeepers/removeTimekeeper/selectMatters/selectTimekeeper")
  }

  /**
   * function to go back to previous screen
   */
  const handleBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className={classes.heading}>
        <Grid>
          <GridColumn sm={12} md={6} lg={10}>
            {SELECT_FROM_MATTERS}
          </GridColumn>
          <GridColumn sm={12} md={6} lg={1}>
            <Button size="medium" variant="tertiary" data-testId="go-back" id="go-back" onClick={() => handleBack()}>
              {CANCEL}
            </Button>
          </GridColumn>
          <GridColumn sm={12} md={6} lg={1} >
            <Button size="medium" variant="primary" data-testId="submit-request" id="submit-request" onClick={() => selectTimekeepers()} disabled={selectedData?.length <= 0}>
              {SELECT_TIMEKEEPERS}
            </Button>
          </GridColumn>
        </Grid>
      </div >
      <div className={classes.dataTableWrap}>
        <MuiDataTable
          data={matterListingData?.matters}
          columns={matterColumns}
          setTableColumns={setmatterColumns}
          customOptions={matterTableCustomOptions}
          loading={loading}
          selectableRowsType={"single"}
          hideAddNewButton={true}
          disableExport={true}
          showSelectedRow={true}
          selectedRows={selectRowsData}
          onRowSelection={handleRowSelectionChange}
          selectedRowMsg={MATTER_MESSAGE}
        />
      </div>
    </React.Fragment>
  );
};

export default RemoveTimekeepersSelectMatters;
