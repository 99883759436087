import { Body, Button, Grid, GridColumn, Heading, useSnackbar, Spinner, Alert } from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EMAIL, FORGOT_PASSWORD, LOGIN, OC_INVOICE_COORDINATOR, PASSWORD,
  SHOW, SUCCESS, HIDE,
  LOG_IN, NO_CACHE_HEADER, LOCKED_STATE,
} from '../../constants/constants';
import { WalmartSpark } from '../../constants/images';
import { setAuthSign, setAuthTkn, setLogout, setStaticTk, setTknDetails, setUserLoginDetails, setUserLoginTenantId } from "../../store/actions/userActions";
import { setOrgDetailValues } from "../../store/actions/organizationAction";
import LoginPageStyles from './LoginPage.styles';
import { USER_SERVICE, ORGANIZATION_SERVICE, NODE_JS_URL, PEOPLE_SEARCH_SERVICE } from "../../constants/baseURLs";
import { postService, getService, postServiceWithHeaders, getServiceWithHeaders } from "../../axios/axios";
import { UNAUTHORISED_ACCESS_MSG, ROLE_CODE_EMPTY_MESSAGE, USER_INACTIVITY_MSG, FORGOT_PASSWORD_HINT, EMAIL_PASSWORD_INCORRECT } from "../../constants/messages";
import { useForm } from "react-hook-form";
import MuiValidationMessage from "../../Validation/MuiValidationMessage/MuiValidationMessage";
import { loginValidationRules } from "../../Validation/validationRules";
import { isValueValid } from "../../helpers/validationUtils";
import { generateSecretKey, handleEncryptedString, getErrorMessage, encryptUserDetail } from "../../helpers/utils";
import { tknInterval } from "../../App";
import TextField from "@mui/material/TextField";
import { getLoginFailedMessageWithForgotPassword } from "../../constants/templates";

/*
*    Error Page is displayed in case of any errors
*    and the page is not accessible
*/
const LoginPage = (props) => {
  const { inactivityMsg } = props;
  const classes = LoginPageStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('password')
  const history = useHistory()
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState();
  const [invalidUserError, setInvalidUserError] = useState('');
  const userDetail = useSelector((state) => state?.user?.userDetail);
  const { register, formState: { errors, isValid, isDirty } } = useForm({ mode: "all" });
  const [showInactivityMsg, setShowInactivityMsg] = useState(false);
  const [showLockedUserErrorMsg, setShowLockedUserErrorMsg] = useState(false);

  useEffect(()=>{
    if(inactivityMsg) {
      setShowInactivityMsg(true)
    }else{
      setShowInactivityMsg(false)
    }
  },[inactivityMsg])

  // Resetting data on load of component
  useEffect(() => {
    localStorage.clear();
    dispatch(setLogout());
    setInvalidUserError('');
  }, [])

  /**
  *  API call to fetch static tk
  */
  const getTK = () => {
    getService(NODE_JS_URL, "/getTk").then((res) => {
      dispatch(setStaticTk(res?.data));
    }).catch(err => {
      // Letting API fail silently
    })
  }

  /**
 *  API call to Node js for fetching configurations
 */
  const getConfig = () => {
    getService(NODE_JS_URL, "/getConfiguration").then((res) => {
      dispatch(setUserLoginTenantId(res?.data?.configResolution?.resolved?.tenantId));
    }).catch(err => {
      // Letting API fail silently
    })
  }

  /**
* getA2ADetails - This method used to fetch App to App Auth Details
*/
  const getA2ADetails = () => {
    getServiceWithHeaders(NODE_JS_URL,  "/getA2ATk?"+new Date().getTime(), NO_CACHE_HEADER).then((res) => {
      dispatch(setAuthSign(res?.data));
    }).catch(err => {
      console.log("Err from node app", err)
      dispatch(setAuthSign({}));
    }).finally(() => {
      // API to fetch principal tk
      setTimeout(() => {
        getPrincipalTk();
      }, 2000);
    })
  }


  /** LOGIN FLOW STARTS HERE */
  /**
   *  Function will get called on click of Login Button
   */
  const handleLogin = () => {
    setInvalidUserError('');
    setLoading(true);
    getConfig();
    getTK();
    clearInterval(tknInterval);
    setTimeout(() => {
      getA2ADetails();
    }, 1000);
  }

  /**
   * Function to fetch principal tk
   */
  const getPrincipalTk = () => {
    getService(USER_SERVICE,
      `/users/v1/token/${email}`
    ).then((response) => {
      // API to fetch user auth status
      getUserAuthStatus(response?.data);
    }).catch((error) => {
      setLoading(false);
      addSnack({ message: getErrorMessage(error) });
    });
  }

  /**
   * getUserAuthStatus - Function to fetch user's authentication status
   * @param {*} tkResponse
   */
  const getUserAuthStatus = (tkResponse) => {
    const token = tkResponse?.payload?.principalAuthnToken?.authToken;
    const entityId = generateSecretKey();
    const encryptedPassword = encryptUserDetail(password, email);
    const headers = { 'X-Entity-Id': entityId, 'WM_SEC_AUTH_TOKEN': token }
    const payload = handleEncryptedString(`${email}|${encryptedPassword}`, entityId);
    const body = { "payload": payload };
    postServiceWithHeaders(USER_SERVICE, `/users/v1/authenticate`,
      body, headers
    ).then((response) => {
      const data = JSON.parse(response?.data);
      const authStatus = data?.payload?.authenticationState;
      let authStatusMsg = data?.payload?.authenticationResultMsg;
      if (authStatus === SUCCESS) {
        dispatch(setAuthTkn(data?.payload?.authenticationToken?.authToken))
        setTimeout(() => {
          getUserDetails(data, entityId);
        }, 2000);
        setInvalidUserError('')
        setShowLockedUserErrorMsg(false);
      } else {
        if (authStatus == LOCKED_STATE) {
          setShowLockedUserErrorMsg(true);
        } else if(authStatusMsg?.toLowerCase() == EMAIL_PASSWORD_INCORRECT) {
          setInvalidUserError(getLoginFailedMessageWithForgotPassword(authStatusMsg))
        } else {
          setInvalidUserError(authStatusMsg);
        }
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      setInvalidUserError(getErrorMessage(error));
    });
  }

  /**
   * getUserDetails - function to
   * @param {*} userData
   * @param {*} entityId
   */
  const getUserDetails = (userAuthData, entityId) => {
    const metadata = [{
      "filters": [{
        "property": 'userId',
        "value": userAuthData?.payload?.principal?.loginId,
        "operator": "="
      },{
        "property": 'isActive',
        "value": true,
        "operator": "="
      },{
        "property": 'status',
        "value": "Active",
        "operator": "="
      }],
      "op": "AND",
      "metadata": null
    }];
		const body = {
			countQuery: false,
			graphId: "LNG",
			nodeType: "DATA_NODE",
			objectType: "PEOPLE",
			metadata: metadata
		}
		postService(PEOPLE_SEARCH_SERVICE, `/search/people/v1/?isCsv=${false}`, body)
      .then((res) => {
        const userDetails = res?.data?.result?.peopleList?.nodeList[0];
        if (userDetails?.identifier) {
          if (!(userDetails?.roleCodes && userDetails?.roleCodes[0])){
            setInvalidUserError(ROLE_CODE_EMPTY_MESSAGE);
            setLoading(false);
          }else  if (isValueValid(userDetails?.organizationId)) {
            // API to fetch org details
            getOrgDetails(userDetails?.organizationId, userAuthData, userDetails, entityId);
          }
        }
        else {
          setInvalidUserError(UNAUTHORISED_ACCESS_MSG);
          setLoading(false);
        }
      })
      .catch((error) => {
        setInvalidUserError('');
        setLoading(false);
        addSnack({message: getErrorMessage(error)})
      });
  }

  /**
   * mapUserDetails - function to map user details into reducer
   * @param {*} userAuthData
   * @param {*} userDetails
   * @param {*} entityId
   */
  const mapUserDetails = (userAuthData, userDetails, entityId) => {
    const { authToken } = userAuthData?.payload?.authenticationToken;
    const allUserDetails = {
      ldapId: userAuthData?.payload?.principal?.loginId,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      roleCode: userDetails?.roleCodes && userDetails?.roleCodes[0],
      primaryContactNo: userDetails?.phoneNumber,
      userMasterId: userDetails?.identifier,
      principalId: userAuthData?.payload?.principal?.id,
      creationTime: new Date(),
      lastLoginTime: userAuthData?.payload?.principal?.lastLoginTime,
      attributes: {
        userId: userAuthData?.payload?.principal?.loginId,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        primaryContactNo: userDetails?.phoneNumber,
        workContactNo: null,
        emailId: userAuthData?.payload?.principal?.mailId,
        userUniqueId: userDetails?.identifier,
        organizationId: userDetails?.organizationId
      }
    }
    const tknDetails = {
      privatePass: authToken,
      privatePassCreationTime: new Date(),
      refreshToken: userAuthData?.payload?.refreshToken,
      refreshTokenCreationTime: new Date(),
      validity: userAuthData?.payload?.authenticationToken?.validity
    }
    dispatch(setTknDetails(tknDetails));
    localStorage.setItem("entityId", entityId);
    localStorage.setItem("userDetails", encryptUserDetail(JSON.stringify(allUserDetails), entityId));
    localStorage.setItem("metadata", encryptUserDetail(JSON.stringify(tknDetails), entityId));
    dispatch(setUserLoginDetails(allUserDetails));
  }

  /**
   * getOrgDetails - function to fetch org details
   * @param {*} organizationId
   */
  const getOrgDetails = (organizationId, userAuthData, userDetails, entityId) => {
    getService(ORGANIZATION_SERVICE, `/organizations/v1/${organizationId}`)
      .then((res) => {
        const orgDetail = res?.data?.result?.organization;
        if(orgDetail?.status === "Active"){
          dispatch(setOrgDetailValues(orgDetail));
          mapUserDetails(userAuthData, userDetails, entityId);
          setInvalidUserError('');
        }
        else{
          setInvalidUserError(UNAUTHORISED_ACCESS_MSG);
          setLoading(false);
        }
      }).catch((err) => {
        setInvalidUserError(UNAUTHORISED_ACCESS_MSG);
        setLoading(false);
      });
  }
  /** LOGIN FLOW ENDS HERE */

  useEffect(() => {
    if (userDetail?.roleCode && userDetail?.roleCode === OC_INVOICE_COORDINATOR) {
      history.push("/financial")
    } else {
      history.push("/")
    }
  }, [userDetail])

  /* This function is called on click of Login */
  const handleForgotPassword = () => {
    history.push("/forgotPassword")
  }
  /* This function is called on show button */
  const handleShow = () => {
    if (type === 'text') {
      setType('password')
    } else {
      setType('text')
    }
  }

  const handleChangePassword = (e) => {
    setShowInactivityMsg(false);
    setInvalidUserError('');
    setPassword(e.target.value.trim())
  }

  const handleChangeEmail = (e) => {
    setShowInactivityMsg(false);
    setInvalidUserError('');
    setEmail(e.target.value)
  }

  /**
   * Fucntion to handle enterkey press for login
   * @param {object} e - keydown event
   */
  const handleKeyDown = (e) => {
    if(!loading || (isValid && isDirty)) {
      const code = (e.keyCode ? e.keyCode : e.which);
      if(code === 13) { //Enter keycode
        handleLogin();
      }
    }
  };

  return (
    <div className={classes.loginPageContainer} data-testid="loginpage-container">
      <div className={classes.loginHeading}>
        <WalmartSpark className={classes.spark}></WalmartSpark>
        <div className={classes.loginText}>
          <Heading as="h1" size="medium" weight={700}>
            {LOG_IN}
          </Heading>
        </div>
      </div>
      <Grid>
        <GridColumn sm={12}>
          <div className={classes.errorInvalidUser}>
            { showLockedUserErrorMsg && <Alert variant="error"> Your account has been locked due to incorrect password being entered. Please use Forgot Password option to unlock.</Alert> }
            {invalidUserError && <Alert variant="error" id="error-invalid-user">{invalidUserError}</Alert>}
            {showInactivityMsg && <Alert variant="error" id="error-inactive-user">{USER_INACTIVITY_MSG}</Alert>}
          </div>
        </GridColumn>
        <GridColumn sm={12}>
          <div className={classes.label}>
            <Body as="p" size="small">
              <strong>{EMAIL}</strong>
            </Body>
          </div>
          <TextField
            type="email"
            data-testid="email"
            id= "email"
            name= "email"
            onKeyDown={handleKeyDown}
            value={email}
            error={errors?.email}
            helperText={errors?.email && <MuiValidationMessage message={errors?.email?.message}/>}
            {...register(
              'email',
              { ...loginValidationRules.email, onChange: (e) => {handleChangeEmail(e)} }
            )}
            fullWidth={true}
          />
        </GridColumn>
        <GridColumn sm={12}>
          <div className={classes.label}>
            <Body as="p" size="small">
              <strong>{PASSWORD}</strong>
            </Body>
          </div>
          <TextField
            type={type}
            data-testid="password"
            id= "pwd"
            name= "password"
            className={classes.passwordField}
            onKeyDown={handleKeyDown}
            value={password}
            error={errors?.password}
            helperText={errors?.password && <MuiValidationMessage message={errors?.password?.message}/>}
            {...register(
              'password',
              { ...loginValidationRules.password, onChange: (e) => {handleChangePassword(e)} }
            )}
            InputProps={{
              endAdornment: <div className={classes.showButton}>
              <Button size="medium" variant="tertiary" onClick={handleShow} data-testid="show-button">
                {(type === 'password') ? SHOW : HIDE}
              </Button></div>,
            }}
            fullWidth={true}
          />
        </GridColumn>
        <GridColumn sm={12}>
          <div className={classes.button}>
            <Button size="medium" variant="tertiary" onClick={handleForgotPassword} data-testid="reset-button" id="forgot-password-btn" disabled={loading}>
              {FORGOT_PASSWORD}
            </Button>
          </div>
        </GridColumn>
        <GridColumn sm={12}>
          <div className={classes.loginButton}>
            <Button
              size="medium"
              variant="primary"
              disabled={loading || !(isValid && isDirty)}
              isFullWidth
              onClick={handleLogin}
              data-testid="login-button"
              id="login-button"
              trailing={loading && <Spinner color="white" size="small" />}
            >
              {LOGIN}
            </Button>
          </div>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default LoginPage;
